import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import queryString from "query-string"
import {
  HeaderCar,
  Loader,
  Header,
  Footer,
  SEO,
  CarViewConfig,
  OptionsViewConfig,
} from "../components"
import { CarContext } from "../context"
import {
  decodeSessionString,
  searchMinimunVersion,
  checkValidSelections,
  cleanConfigSelections,
  infoToSessionString,
  getVersionIdsBySelections,
} from "../utils"

export default function ConfigSuzukiNuevoSwift({
  data,
  pageContext: { modelId },
}) {
  const appContext = useContext(CarContext)
  const {
    transmission,
    setTransmission,
    equipment,
    setEquipment,
    engine,
    setEngine,
    traction,
    setTraction,
    color,
    setColor,
    selectedVersion,
    setSelectedVersion,
    comparatorSelections,
    setComparatorSelections,
    setTerms,
    loading,
    modelsPricesStocks,
  } = appContext

  const model = data.allModels.nodes.find(model => model.ID === modelId)

  const selections = {
    transmission,
    setTransmission,
    equipment,
    setEquipment,
    engine,
    setEngine,
    traction,
    setTraction,
    color,
    setColor,
  }

  if (typeof window !== `undefined`) {
    window.addEventListener("scroll", event => {
      const active = window.pageYOffset > 1
      if (active) {
        const header = document.querySelector(".config>.header")
        if (header) {
          header.classList.add("sticky-active")
        }
      } else {
        const header = document.querySelector(".config>.header")

        if (header) {
          header.classList.remove("sticky-active")
        }
      }
    })
  }

  let querys = queryString.parse(
    typeof window !== "undefined" && window.location.search
  )
  const queryParams = decodeSessionString(querys.session)

  if (queryParams && !checkValidSelections(model, queryParams)) {
    cleanConfigSelections(selections, true)
    infoToSessionString({ modelId: model.ID })
  }
  if (!queryParams?.modelId && model) infoToSessionString({ modelId: model.ID })

  // CALCULATE CORRECT MINIMUN PRICES IF THEY ARE WRONG OR INEXISTENT
  const modelVersionsIds = model.versions.map(v => v.id)
  const minimunPrices = searchMinimunVersion(
    model,
    queryParams,
    modelsPricesStocks,
    getVersionIdsBySelections(
      modelVersionsIds,
      model,
      selections,
      modelsPricesStocks
    ),
    { allowOutOfStock: true }
  )

  /* Select the chepeast version when
      a) There is no selected version, which happens when the page is reloaded
      b) The version selected is different that the minimun calculated based on {selections}, that happens when that version doesnt belongs to the current model
  */
  useEffect(() => {
    if (!color && minimunPrices?.versionId !== selectedVersion?.versionId) {
      setSelectedVersion(minimunPrices)
    }
  }, [color, minimunPrices, selectedVersion, setSelectedVersion])

  return (
    <>
      <SEO
        title={`Configura tu ${model.name} | DercoCenter X`}
        url={`https://dercocenterx.cl/config/${model.slug}`}
        description={`Configura tu próximo ${model.name} con los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio.`}
      ></SEO>
      <Header />
      <div className="config">
        <HeaderCar
          page="config"
          model={model}
          context={{
            comparatorSelections,
            setComparatorSelections,
          }}
        ></HeaderCar>
        <div className="side-to-side">
          <CarViewConfig
            model={model}
            selections={selections}
            pricesStocks={modelsPricesStocks}
            appContext={appContext}
            terms={setTerms}
          ></CarViewConfig>
          <OptionsViewConfig
            model={model}
            selections={selections}
            pricesStocks={modelsPricesStocks}
            appContext={appContext}
          ></OptionsViewConfig>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </>
  )
}

export const query = graphql`
  query FULL_QUERY_6 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
  }
`
